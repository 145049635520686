import { useState } from "react";
import Login from "../components/auth/Login";

const Auth = () => {
    const [data, setData] = useState({});

    const containerStyle = {
        width: '100vw',
        height: '100vh',
        backgroundColor: '#0F172A',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    return (
        <div style={containerStyle}>
            <Login data={data} setData={setData} setScreen={() => {}} />
        </div>
    );
};

export default Auth;
