import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { io } from 'socket.io-client';
import { Box, Typography, Button, Snackbar, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid2';
import PhoneIcon from '@mui/icons-material/Phone';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { baseUrl } from '../core';

const columns = [
  { field: 'phoneNumber', headerName: 'Phone', width: window.innerWidth / 7 },
  { field: 'firstName', headerName: 'First Name', width: window.innerWidth / 7 },
  { field: 'lastName', headerName: 'Last Name', width: window.innerWidth / 7 },
  {
    field: 'userResponse',
    headerName: 'User Response',
    width: window.innerWidth / 7,
    renderCell: (params) => {
      console.log('User Response Params:', params);
      let bgcolor;
      if (params.value === 'Interested') {
        bgcolor = 'success.main';
      } else if (params.value === 'Not-interested') {
        bgcolor = 'error.main';
      } else if (params.value === 'New') {
        bgcolor = 'primary.main';
      } else if (params.value === 'Do-not-call-again') {
        bgcolor = 'error.main';
      } else {
        bgcolor = 'warning.main';
      }

      return (
        <Box
          sx={{
            bgcolor: bgcolor,
            color: 'white',
            p: 1,
            borderRadius: 1,
            textAlign: 'center',
          }}
        >
          {params.value}
        </Box>
      );
    },
  },
  { field: 'callStatus', headerName: 'Call Status', width: window.innerWidth / 7 },
  {
    field: 'lastCallTimestamp',
    headerName: 'Last Called',
    width: window.innerWidth / 7,
    renderCell: (params) => {
      if (!params?.value) return '';
      const date = new Date(params?.value);
      const formattedDate = date.toISOString().split('T')[0];
      const formattedTime = date.toTimeString().split(' ')[0].slice(0, 5);
      return `${formattedDate}, ${formattedTime}`;
    },
  },
  {
    field: 'recallDate',
    headerName: 'Re Called',
    width: window.innerWidth / 7,
    renderCell: (params) => {
      if (!params?.value) return '';
      const date = new Date(params?.value);
      const formattedDate = date.toISOString().split('T')[0];
      const formattedTime = date.toTimeString().split(' ')[0].slice(0, 5);
      return `${formattedDate}, ${formattedTime}`;
    },
  },
];

const Home = () => {
  const [file, setFile] = useState(null);
  const [rows, setRows] = useState([]);
  const [socket, setSocket] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [isUpLoading, setIsUpLoading] = useState(false);
  const [uploadSuccessMessage, setUploadSuccessMessage] = useState('');
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [summary, setSummary] = useState({
    totalCalls: 0,
    interestedCount: 0,
    notInterestedCount: 0,
    noResponseCount: 0
  });

  const fetchCallSummary = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/v1/calls/summary`, { withCredentials: true });
      setSummary(response.data);
    } catch (error) {
      console.error('Error fetching call summary:', error);
    }
  };

  useEffect(() => {
    fetchCallSummary();
  }, []);

  useEffect(() => {
        fetchCallData();
        fetchCallSummary();
        // Initialize socket connection
        const socketConnection = io(baseUrl);
        setSocket(socketConnection);

        // Listen for updates from the server
        socketConnection.on('update-calls', (data) => {
            console.log('Received update from server:', data);
            // Fetch the updated call data
            fetchCallData();
            fetchCallSummary();
        });

        // Cleanup socket connection on component unmount
        return () => {
            socketConnection.disconnect();
        };
    }, []);

  const fetchCallData = async (page) => {
    console.log("Fetching call data...");
    try {
      setLoading(true);
      const response = await axios.get(`${baseUrl}/api/v1/calls?page=${rows.length}`, { withCredentials: true });
      if (response.status === 200) {
        const _rows = response.data.map((data) => ({ ...data, id: data._id }));

        setRows((prevRows) => {
          // Create a Map for quick lookup of existing rows
          const existingRowsMap = new Map(prevRows.map(row => [row.id, row]));

          _rows.forEach(newRow => {
           // Check if the row already exists
           if (existingRowsMap.has(newRow.id)) {
            // Update the existing row
            existingRowsMap.set(newRow.id, { ...existingRowsMap.get(newRow.id), ...newRow });
           } else {
            // Add the new row
            existingRowsMap.set(newRow.id, newRow);
           }
         });

        // Convert the map back to an array
        return Array.from(existingRowsMap.values());
      });
     }
    } catch (error) {
      console.error('Error fetching call data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      setPage((prevPage) => {
        const nextPage = prevPage + rows.length;
        fetchCallData(nextPage);
        return nextPage;
      });
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    setIsUpLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(`${baseUrl}/api/v1/upload-numbers`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }, { withCredentials: true });
      console.log('Upload successful:', response.data);
      setUploadSuccessMessage('Upload successful!');
      setUploadSuccess(true);
      setFile(null); // Reset the file state after upload
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setIsUpLoading(false);
    }
  };

  const handleDownload = async () => {
    setIsDownloadLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/api/v1/download-report`, {
        responseType: 'blob',
      }, { withCredentials: true });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading report:', error);
    } finally {
      setIsDownloadLoading(false);
    }
  };

  const handleMakeCall = async () => {
    try {
      const response = await axios.post(`${baseUrl}/api/v1/make-call`, { withCredentials: true });
      setMessage('Calls initiated successfully!');
    } catch (error) {
      setMessage(`Error initiating calls: ${error.message}`);
    } finally {
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        User Interest Dashboard
      </Typography>
      <Grid container spacing={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ bgcolor: 'primary.main', color: 'white', p: 2, textAlign: 'center' }}>
              <Typography variant="h6">Total Users</Typography>
              <Typography variant="h4">{summary.totalCalls}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ bgcolor: 'secondary.main', color: 'white', p: 2, textAlign: 'center' }}>
              <Typography variant="h6">Interested</Typography>
              <Typography variant="h4">{summary.interestedCount}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ bgcolor: 'warning.main', color: 'white', p: 2, textAlign: 'center' }}>
              <Typography variant="h6">Not Interested</Typography>
              <Typography variant="h4">{summary.notInterestedCount}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ bgcolor: 'info.main', color: 'white', p: 2, textAlign: 'center' }}>
              <Typography variant="h6">No Response</Typography>
              <Typography variant="h4">{summary.noResponseCount}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: '20px' }}>
          <Grid item xs={12} sm={6}>
            <Button variant="contained" component="label">
              Upload Phone Number
              <input type="file" hidden accept=".xlsx" onChange={handleFileChange} />
            </Button>
            <Button
              variant="contained"
              onClick={handleUpload}
              sx={{ bgcolor: 'success.main', '&:hover': { bgcolor: 'success.dark' } }}
              disabled={!file || isUpLoading}
            >
              {isUpLoading ? <CircularProgress size={24} /> : 'Upload'}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button variant="contained" onClick={handleDownload} disabled={isDownloadLoading}>
              {isDownloadLoading ? <CircularProgress size={24} /> : 'Download Report'}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button variant="contained" startIcon={<PhoneIcon />} onClick={handleMakeCall}>
              make call
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Paper sx={{ height: 450, width: '100%', marginTop: '20px', overflowY: 'auto' }} onScroll={handleScroll}>
        {loading ? (
          <Typography>Loading...</Typography>
        ) : (
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                {columns.map((column) => (
                  <th key={column.field} style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>
                    {column.headerName}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => (
                <tr key={row.id}>
                  {columns.map((column) => (
                    <td key={column.field} style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>
                      {column.renderCell ? column.renderCell({ value: row[column.field], row }) : row[column.field]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Paper>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={message}
      />
      <Snackbar
        open={uploadSuccess}
        autoHideDuration={6000}
        onClose={() => setUploadSuccess(false)}
        message={uploadSuccessMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ backgroundColor: 'success.main' }}
      />
    </div>
  );
}

export default Home;
