import logo from "../assets/images/logo.png";

const SplashScreen = () => {
    const containerStyle = {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const imgStyle = {
        width: '10em',
        height: '10em',
        objectFit: 'contain',
    };

    return (
        <div style={containerStyle}>
            <img src={logo} alt="logo" style={imgStyle} />
        </div>
    );
};

export default SplashScreen;
