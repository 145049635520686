import "./App.css";
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Auth from './pages/Auth';
import SplashScreen from './components/SplashScreen';
import { useEffect } from 'react';
import axios from 'axios';
import { baseUrl } from './core';
import { login, logout } from './redux/user';
import { ThemeProvider } from '@mui/system';
import { theme } from './mui/theme';
const App = () => {
    const currentUser = useSelector((state) => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        axios.interceptors.request.use(
            function (config) {
                config.withCredentials = true;
                return config;
            },
            function (error) {
                return Promise.reject(error);
            }
        );

        return () => {
            // cleanup function
        };
    }, []);

    useEffect(() => {
        const checkLoginStatus = async () => {
            try {
                const resp = await axios.get(`${baseUrl}/api/v1/profile`, {
                    withCredentials: true,
                });
                dispatch(login(resp?.data?.data));
            } catch (error) {
                console.error(error);
                dispatch(logout()); // This should trigger a state change
            }
        };
    
        checkLoginStatus();
    }, [dispatch]);

    return (
        <ThemeProvider theme={theme}>
            <>
                {currentUser?.isLogin === true && currentUser?.isAdmin === true && (
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                )}
                {currentUser?.isLogin === false && (
                    <Routes>
                        <Route path="/" element={<Auth />} />
                    </Routes>
                )}
                {currentUser?.isLogin === null && <SplashScreen />}
            </>
        </ThemeProvider>
    );
};

export default App;
