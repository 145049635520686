import { Button, TextField } from "@mui/material";
import logo from "../../assets/images/logo.png"
import PasswordField from "../../mui/components/PasswordField";
import { baseUrl} from "../../core"
import { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux"
import { login } from "../../redux/user"

const Login = ({ data, setData, setScreen }) => {
    const dispatch = useDispatch()
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault();

        if (!email || email.trim() === "") {
            setErrorMessage("Email is required");
            setTimeout(() => setErrorMessage(null), 3000);
            return;
        }

        if (!password || password.trim() === "") {
            setErrorMessage("Password is required");
            setTimeout(() => setErrorMessage(null), 3000);
            return;
        }

        try {
            setIsLoading(true);
            const loginResp = await axios.post(`${baseUrl}/api/v1/login`, { email, password }, { withCredentials: true }); // Adjust the URL as necessary
            
            setData({ ...data, email })
            // setScreen(null)
            setIsLoading(false)

            dispatch(login(loginResp?.data?.data))
            // Handle successful login (e.g., redirect or set user state)
        } catch (error) {
            setIsLoading(false);
            setErrorMessage("Login failed. Please check your credentials.");
            setTimeout(() => setErrorMessage(null), 3000);
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '20px' }}>
            {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
            <img src={logo} alt="logo" style={{ width: '150px', height: '150px', borderRadius: '50%', objectFit: 'contain' }} />
            <form onSubmit={handleLogin} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '24px', padding: '16px', backgroundColor: '#fff', borderRadius: '24px' }}>
                <TextField
                    label="Email address"
                    placeholder="Enter email..."
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{ width: 480 }}
                />
                <PasswordField
                    onChange={(val) => setPassword(val)}
                    label="Password"
                    placeholder="Enter password"
                    width={480}
                />
                <Button type="submit" disabled={isLoading} fullWidth variant="contained" color="secondary">
                    Login
                </Button>
            </form>
        </div>
    );
};

export default Login;
