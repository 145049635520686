import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLogin: null, // initially unknown
    isAdmin: false,
    // add any other user-related properties here
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state, action) => {
            state.isLogin = true;
            state.isAdmin = action.payload.isAdmin; // or any other properties you need
            // add other user properties as needed
        },
        logout: (state) => {
            state.isLogin = false;
            state.isAdmin = false; // reset admin status on logout
            // reset any other user properties if necessary
        },
    },
});

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;
